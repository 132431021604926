import React from 'react'
import { graphql, Link } from 'gatsby'
// eslint-disable-next-line
import PropTypes from 'prop-types'

import {
  Heading,
  Button,
  Container,
  Hero,
  // Icon,
  Section,
  Box,
  Content,
  Table
} from 'react-bulma-components'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'

import ArtPortfolioSectionBg from '../components/ArtPortfolioSectionBg'

// eslint-disable-next-line
const IconBox = ({ children, ...props }) =>
  (
  <div
    css={{
      display: 'flex',
      backgroundColor: '#F1F2FD',
      borderRadius: 6,
      padding: '1rem',
      margin: '.5rem',
      width: 65
    }}
    {...props}
  >
    {children}
  </div>
)

// eslint-disable-next-line
const DescriptionBlock = ({ title, description, ...props }) =>
(
  <div
    css={{
      display: 'flex'
    }}
    {...props}
  >
    <p css={{ color: '#5B59FB', fontWeight: 'bold' }}>{title}</p>
  </div>
)

class CookiePolicy extends React.Component {
  getImage(imageName) {
    return this.props.data[imageName].childImageSharp.fluid
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        bodyInContainer={false}
        className="index"
      >
        <SEO title="Cookie notice" />
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 200 }}>
              <Container className="has-text-centered">
                <Section>
                  <Heading size={1} renderAs="h1">
                    Cookie Notice
                  </Heading>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <Container className="has-text-left">
              <Section>
                <Heading size={6} renderAs="h5">
                  Last updated: 1/01/2020
                </Heading>
                <Content>
                  <p>
                    We are Tangibly OU, registered at Harju maakond, Tallinn,
                    Kesklinna linnaosa, Sakala tn 7-2, 10141, Estonia in this
                    document we will call ourselves “we” or “us”. We use cookies
                    on our Website located at tangibly.tech. This Cookie Notice
                    is here to help you understand how we use cookies and how
                    you can control the use of cookies. If you have any further
                    questions, please send us an email to{' '}
                    {this.decodeSupportURL()}, you can also send us a letter to
                    our address listed above.
                  </p>
                  <Heading size={2} renderAs="h2">
                    What is a cookie?
                  </Heading>
                  <p>
                    A cookie is a small text file that is stored on your device.
                    Cookies are widely used to make online services work or to
                    be better and more efficient. They can do this because
                    online services can read and write these files, enabling
                    them to recognize you and remember important information
                    that will make your use of them more convenient (e.g. by
                    remembering your user preferences).
                  </p>
                  <Heading size={2} renderAs="h2">
                    What cookies do we use?
                  </Heading>
                  <p>
                    Below we list the different types of cookies we may use.
                  </p>
                  <Table>
                    <thead>
                      <tr>
                        <th>Cookie</th>
                        <th>Name</th>
                        <th css={{ width: '15%' }}>Cookie Expires</th>
                        <th>Purpose</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Universal Analytics (Google)</td>
                        <td>
                          _ga
                          <br />
                          _gali
                          <br />
                          _gat
                          <br />
                          _gid
                          <br />
                        </td>
                        <td>
                          _ga _days
                          <br />
                          _gali _days
                          <br />
                          _gat _days
                          <br />
                          _gid _days
                          <br />
                        </td>
                        <td>
                          These cookies are used to collect information about
                          how our website is used. We use the information to
                          improve the website. The cookies collect information
                          in a way that does not directly identify anyone. They
                          collect the number of visitors to the website, where
                          visitors have come from and the pages they visited.
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Heading size={2} renderAs="h2">
                    How can you manage cookie settings?
                  </Heading>
                  <p>
                    You can use your browser or device settings to control
                    cookies, links below provide information about cookie
                    settings for the browsers:
                  </p>
                  <ul>
                    <li>
                      <a
                        href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Firefox
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Microsoft Edge
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.google.com/chrome/answer/95647"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Chrome
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Safari
                      </a>
                    </li>
                  </ul>
                  <p>
                    Also, you can opt-out by visiting the{' '}
                    <a
                      href="http://optout.networkadvertising.org/?c=1#!/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Network Advertising Initiative Opt Out
                    </a>{' '}
                    page.
                  </p>
                  <Heading size={2} renderAs="h2">
                    Changes to this Cookie Notice
                  </Heading>
                  <p>
                    We can update this Cookie Notice if necessary. Any changes
                    will come into effect 30 days later. Please, check out “last
                    updated” date at the top of this page to see when this
                    Cookie Notice was last revised.
                  </p>

                  <Heading size={2} renderAs="h2">
                    More about privacy
                  </Heading>
                  <p>
                    Please also read our{' '}
                    <Link to="/privacy-policy">Privacy Notice</Link>, which
                    gives more information about how we use your personal data.
                  </p>
                </Content>
              </Section>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 300 }}>
              <Container>
                <Section>
                  <Box>
                    <Section
                      style={{
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        <Heading size={2} renderAs="h2">
                          Let&apos;s talk
                        </Heading>
                        <Heading subtitle size={5} renderAs="h3">
                          Software development solutions that <br /> solve real
                          business needs.
                        </Heading>
                      </div>
                      <Button
                        color="primary"
                        style={{
                          display: 'flex',
                          fontWeight: 'bold'
                        }}
                        onClick={this.openMailTo}
                      >
                        GET ESTIMATE
                      </Button>
                    </Section>
                  </Box>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
      </Layout>
    )
  }

  decodeSupportURL = () => {
    const encoded = 'ifmmpAubohjcmz/ufdi'
    let link = ''
    for (let i = 0; i < encoded.length; i++)
      link += String.fromCharCode(encoded.charCodeAt(i) - 1)

    return link
  }

  openMailTo = e => {
    e.preventDefault()
    const link = this.decodeSupportURL()

    window.location.href = `mailto:${link}`
  }
}

CookiePolicy.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}
export default CookiePolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
